import React, { FC } from 'react';
import Head from 'next/head';

import { GetStaticPathsResult, GetStaticProps, GetStaticPropsResult } from 'next';
import { defaultTranslationNamespaces } from '../constants/translations';
import { serverSideNavigation } from '../lib/strapi/server-side-navigation';
import HomeHeader, { HomeHeaderImage } from '../components/header/home-header';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { ssrHome } from '../@types/codegen/page';
import { IDigandoBasicProps } from '../interfaces/basic-props.interface';
import { ApolloError } from '@apollo/client';
import {
  ComponentHeaderHomepageHeader,
  HomepageResponseFragment, StrapiImageFragment,
  AllZonesFragment, ComponentContentPopularCategories,
} from '../@types/codegen/graphql';
import { IProductSearchFilter } from '../store/recoil/product-search';
import { getDefaultFilterValues } from '../hooks/use-product-search-helper';
import { generateWeekends } from '../hooks/use-date-info';
import { i18nextConfig } from '../next-i18next.config';
import { ICanonical } from '../interfaces/canonical.interface';
import { Languages } from '../types/languages.type';
import { getZoneApiCache } from '../zones/zone-cache';
import { IStrapiSeo, StrapiSeo } from '../seo/strapi-seo';
import { Zones } from '../zones/zones';
import { FaqPageSchema } from '../schema.org/faq-page.schema';
import { generateImageUrl } from '../lib/strapi/generate-image-url';
import { StrapiImageFormats } from '../@types/strapi';
import { onlineStoreSchema } from '../schema.org/online-store.schema';
import { websiteSchema } from '../schema.org/website.schema';
import { useTranslation } from 'next-i18next';
import { isFeatureFlagEnabled } from '../lib/environment';

interface ISsrData {
  filterValues: IProductSearchFilter<string>;
}

interface IIndexPageProps extends IDigandoBasicProps<ISsrData>, IStrapiSeo {
  header: {
    image: HomeHeaderImage;
  };
  popularCategories?: ComponentContentPopularCategories;
  content: AllZonesFragment[];
}

export const IndexPage: FC<IIndexPageProps> = (props) => {
  const [t] = useTranslation('schema-org');

  return (
    <>
      {isFeatureFlagEnabled('isSchemaMarkupEnabled') && (
        <Head>
          <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(websiteSchema()) }} />
          <script type='application/ld+json' dangerouslySetInnerHTML={{
            __html: JSON.stringify(onlineStoreSchema({
              name: t('onlineStore.name'),
              slogan: t('onlineStore.slogan'),
              taxID: t('onlineStore.taxID'),
              contactPoint: {
                telephone: t('onlineStore.contactPoint.telephone'),
                email: t('onlineStore.contactPoint.email'),
                contactType: t('onlineStore.contactPoint.contactType'),
              },
              address: {
                addressLocality: t('onlineStore.address.addressLocality'),
                addressRegion: t('onlineStore.address.addressRegion'),
                postalCode: t('onlineStore.address.postalCode'),
                streetAddress: t('onlineStore.address.streetAddress'),
                addressCountry: t('onlineStore.address.addressCountry'),
              },
              foundingDate: t('onlineStore.foundingDate'),
              iso6523Code: t('onlineStore.iso6523Code'),
            })),
          }} />
        </Head>
      )}

      <StrapiSeo seo={props.seo} pageTitle={props.pageTitle} schemaOrg={{ enable: true }} />

      <HomeHeader
        headerTitle={props.pageTitle}
        headerImage={props.header.image}
        popularCategories={props.popularCategories}
      />

      <Zones zones={props.content} />
      <FaqPageSchema zones={props.content} />
    </>
  );
};

export const getStaticProps: GetStaticProps<IIndexPageProps> = async (
  props,
): Promise<GetStaticPropsResult<IIndexPageProps>> => {
  const { locale } = props;
  try {
    const homepageReq = ssrHome.getServerPage({});

    const [homepageRes, ssrNavigation] = await Promise.all([homepageReq, serverSideNavigation()]);
    const homepageData: HomepageResponseFragment | null = homepageRes?.props?.data.homepage?.data ?? null;

    const filterValues = getDefaultFilterValues(generateWeekends(), null, null, null);

    const canonicals: ICanonical[] = [];

    canonicals.push({
      lang: locale as Languages,
      url: '',
      alternate: false,
    });

    const cache = await getZoneApiCache(homepageData?.attributes?.content as AllZonesFragment[] ?? []);
    const headerImages = (homepageData?.attributes?.header ?? [])
      .filter((headerImage) => headerImage?.image?.data?.attributes?.url) as ComponentHeaderHomepageHeader[];

    const mainHeader = headerImages[headerImages.length - 1] as ComponentHeaderHomepageHeader;
    const mainHeaderImage = mainHeader?.image?.data?.attributes as StrapiImageFragment;
    const seoImageFormats: StrapiImageFormats | null = homepageData?.attributes?.seo?.image?.data?.attributes?.formats ?? null;
    const popularCategories = homepageData?.attributes?.popularCategories;

    return {
      revalidate: 15 * 60, // Time in seconds to renew cache.
      props: {
        ...(await serverSideTranslations(locale ?? 'de', [...defaultTranslationNamespaces], i18nextConfig)),
        apolloState: {
          ...homepageRes.props.apolloState,
          ...cache?.apolloState ?? {},
          ...ssrNavigation.apolloState,
          ROOT_QUERY: {
            ...homepageRes.props.apolloState?.ROOT_QUERY,
            ...cache?.apolloState?.ROOT_QUERY,
            ...ssrNavigation.apolloState?.ROOT_QUERY,
          },
        },
        data: {
          filterValues: {
            ...filterValues,
            dateRange: {
              gte: filterValues.dateRange.gte.toISOString(),
              lte: filterValues.dateRange.lte.toISOString(),
            },
          },
        },
        popularCategories: {
          id: popularCategories?.id ?? '',
          title: popularCategories?.title ?? '',
          categories: popularCategories?.categories?.map(category => {
            return {
              id: category?.id ?? '',
              slug: category?.slug ?? '',
            };
          }) ?? [],
        },
        pageTitle: homepageData?.attributes?.title ?? '',
        header: {
          image: {
            sources: headerImages.map((headerImage) => {
              return {
                maxWidth: `${headerImage.maxBrowserWidth}px`,
                src: generateImageUrl(headerImage.image.data?.attributes?.url ?? ''),
              };
            }),
            src: mainHeaderImage?.url ? generateImageUrl(mainHeaderImage.url) : null,
            alt: mainHeaderImage?.alternativeText ?? '',
            title: mainHeaderImage?.alternativeText ?? '',
            width: mainHeaderImage?.width as number ?? 0,
            height: mainHeaderImage?.height as number ?? 0,
          },
        },
        seo: {
          title: homepageData?.attributes?.seo?.title ?? homepageData?.attributes?.title ?? 'Digando.com',
          description: homepageData?.attributes?.seo?.description ?? '',
          url: '/',
          image: seoImageFormats?.['1200x630'] ?? null,
          canonicals,
          datePublished: homepageData?.attributes?.createdAt,
          dateModified: homepageData?.attributes?.updatedAt,
        },
        content: homepageData?.attributes?.content as AllZonesFragment[] ?? [],
        cartId: null,
      },
    };
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.error(error);

    if (error instanceof ApolloError) {
      error.graphQLErrors.map(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
    }

    return {
      notFound: true,
      revalidate: 10,
    };
  }
};

export async function getStaticPaths(): Promise<GetStaticPathsResult> {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

