import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import {
  spacingBottom,
  spacingCalculator,
  theme,
} from '@digando/react-component-library';

import { mediaQueries, size } from '../../styles/media-queries';
import { IPopularCategory, PopularCategoriesStatic } from '../popular-categories/popular-categories-static';

import { GlobalSearchBar } from '../global-search/components/global-search-bar';
import { useCategoriesContext } from '../../store/categories.context';
import { Title } from '../elements/Typography/Title/Title';
import { usePageHeaderSearch } from '../../hooks/use-page-header-search';
import Image from 'next/image';
import { generateImageUrl } from '../../lib/strapi/generate-image-url';
import { ComponentContentPopularCategories } from '../../@types/codegen/graphql';

export type HomeHeaderImage = {
  sources: {
    src: string;
    maxWidth: string;
  }[];
  src: string | null
  alt: string;
  title: string;
  width: number;
  height: number;
};

type HomeHeaderProps = {
  headerTitle: string;
  headerImage: HomeHeaderImage;
  popularCategories?: ComponentContentPopularCategories;
}

const HomeHeaderComponent: FC<HomeHeaderProps> = ({ headerTitle, headerImage, popularCategories }) => {
  const [t] = useTranslation('search-component');
  const inputRef = useRef<HTMLDivElement>(null);
  const height = inputRef.current?.offsetHeight ?? 600;

  usePageHeaderSearch(height);

  const { categories } = useCategoriesContext();

  const filteredPopularCategories: IPopularCategory[] = [];
  popularCategories?.categories?.map(category => {
    const categoryData = categories.find(c => c.uriComponent === category?.slug);

    if (categoryData) {
      filteredPopularCategories.push({
        id: categoryData.id,
        title: categoryData.searchTitle,
        imageUrl: categoryData?.image?.url ?? '',
        relativeUrl: categoryData.relativeUrl,
      });
    }
  });

  return (
    <>
      <HomeHeaderWrapper ref={inputRef}>
        {headerImage.src && (
          <HeaderImageContainer>
            <picture>
              {headerImage.sources.map((source, index) => {
                return (
                  <React.Fragment key={source.maxWidth}>
                    <source
                      key={source.maxWidth}
                      srcSet={`${generateImageUrl(source.src)} 1x`}
                      media={`(max-width: ${source.maxWidth})`}
                      type={'image/webp'}
                    />
                  </React.Fragment>
                );
              })}

              <Image
                src={headerImage.src}
                alt={headerImage.alt}
                title={headerImage.title}
                unoptimized={true}
                priority={true}
                width={headerImage.width}
                height={headerImage.height}
              />
            </picture>
          </HeaderImageContainer>
        )}

        <HeaderContent>
          <Inner>
            <TitleSection>
              <Title level={1}>{headerTitle}</Title>
            </TitleSection>

            <GlobalSearchBar identifier={'home-header'} />

            <PopularCategoriesStatic
              popularCategories={filteredPopularCategories}
              headlineTitle={t('search-component:frequently-searched')}
            />
          </Inner>
        </HeaderContent>
      </HomeHeaderWrapper>
    </>
  );
};

const HomeHeaderWrapper = styled.div`
  align-items: center;
  position: relative;
  width: 100%;
`;

const HeaderImageContainer = styled.div`
  display: flex;
  width: 100%;

  img,
  picture {
    width: 100%;
    min-height: 630px;
    height: 630px;
  }

  img {
    object-fit: cover;
    object-position: center center;
  }
`;

const HeaderContent = styled.div`
  max-width: 1050px;
  width: 100%;
  position: absolute;
  top: 30px;
  left: 0;
  padding: 0 ${spacingCalculator(4)};

  justify-content: center;
  flex-direction: column;

  @media (max-width: ${size.tablet}) {
    width: 100vw !important;
    max-width: 100vw !important;
  }

  @media ${mediaQueries.laptop} {
    transform: translateY(-50%) translateX(-50%);
    top: 50%;
    left: 50%;
    height: auto;
    padding: 0;
  }
`;

const Inner = styled.div`
  grid-column: auto / span 12;
  width: 100%;
`;

/**
 * @todo: This CSS should be made more generic. Currently it is optimized for a static header image. This was done during performance optimizations.
 */
const TitleSection = styled.div`
  ${spacingBottom(5)};
  text-align: center;
  display: flex;
  justify-content: center;

  @media ${mediaQueries.tablet} {
    ${spacingBottom(2)};
  }

  h1.ant-typography.title-variant-1 {
    margin: 0 auto;
    width: 100%;
    max-width: 70vw;
    color: ${theme.black} !important;
    font-size: 32px;

    @media ${mediaQueries.laptop} {
      font-size: 48px;
    }
  }
`;

export default React.memo(HomeHeaderComponent, () => {
  return true;
});
