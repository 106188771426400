import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import type { TitleProps as AntdTitleProps } from 'antd/es/typography/Title';
import type { BaseType } from 'antd/es/typography/Base';

import { FontSecondaryLight, FontSecondarySemiBold } from '../../../../styles/fonts';
import { modifySizeMultiply } from '../../../../styles/theme';

import { mediaQueries } from '../../../../styles/media-queries';
import { spacingTop } from '../../../../helper/spacing';

interface IHeadlineStyleProps {
  type?: BaseType;
  variant?: 1 | 2 | 3 | 4 | 5 | 6;
}

export interface ITitleProps extends AntdTitleProps, IHeadlineStyleProps {}

export const Title: FC<ITitleProps> = (props) => {
  let level = props?.level ?? 1;
  const variant = props.variant ?? props.level;

  if (5 < level) {
    level = 5; // H6 and above is not defined in antd.
  }

  return (
    <StyledTypography className={`title-variant-${variant}`} {...props} level={level}>
      {props.children}
    </StyledTypography>
  );
};

Title.defaultProps = {
  ellipsis: false,
};

const StyledTypography = styled(Typography.Title)`
  &.title-variant-1,
  &.title-variant-2,
  &.title-variant-3,
  &.title-variant-4 {

    &.ant-typography,
    &.ant-typography * {
      ${(p): string => ('secondary' === p.type ? FontSecondaryLight : FontSecondarySemiBold)}
      color: inherit;
      white-space: pre-wrap;
    }
  }

  &.title-variant-5,
  &.title-variant-6 {

    &.ant-typography,
    &.ant-typography * {
      ${FontSecondarySemiBold};
      color: inherit;
      white-space: pre-wrap;
      font-size: 17px;
      line-height: 24px;
      ${spacingTop(4)};
    }
  }

  &.title-variant-1.ant-typography,
  &.ant-typography h1,
  &.title-variant-1.ant-typography *,
  &.ant-typography h1 * {
    font-size: ${modifySizeMultiply(4)};
    line-height: ${modifySizeMultiply(4.7)};

    @media ${mediaQueries.laptop} {
      font-size: ${modifySizeMultiply(6)};
      line-height: ${modifySizeMultiply(7)};
    }
  }

  &.title-variant-2.ant-typography,
  &.ant-typography h2,
  &.title-variant-2.ant-typography *,
  &.ant-typography h2 * {
    font-size: ${modifySizeMultiply(3.5)};
    line-height: ${modifySizeMultiply(4.1)};

    @media ${mediaQueries.laptop} {
      font-size: ${modifySizeMultiply(5)};
      line-height: ${modifySizeMultiply(6)};
    }
  }

  &.title-variant-3.ant-typography,
  &.ant-typography h3,
  &.title-variant-3.ant-typography *,
  &.ant-typography h1 * {
    font-size: ${modifySizeMultiply(2.5)};
    line-height: ${modifySizeMultiply(3)};

    @media ${mediaQueries.laptop} {
      font-size: ${modifySizeMultiply(3)};
      line-height: ${modifySizeMultiply(3.5)};
    }
  }

  &.title-variant-4.ant-typography,
  &.ant-typography h4,
  &.title-variant-4.ant-typography *,
  &.ant-typography h4 * {
    font-size: ${modifySizeMultiply(2.1)};
    line-height: ${modifySizeMultiply(2.5)};

    @media ${mediaQueries.laptop} {
      font-size: ${modifySizeMultiply(2.5)};
      line-height: ${modifySizeMultiply(3)};
    }
  }
`;
