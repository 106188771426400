import getConfig from "next/config";

const {publicRuntimeConfig} = getConfig();

export const websiteSchema = (path?: string) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    'url': publicRuntimeConfig.appBaseUrl,
    'potentialAction': {
      '@type': 'SearchAction',
      'target': {
        '@type': 'EntryPoint',
        'urlTemplate': `${publicRuntimeConfig.appBaseUrl}${path ? path : '/baumaschinen-mieten'}?q={search_term_string}`,
      },
      'query-input': 'required maxlength=100 name=search_term_string',
    },
  }
};